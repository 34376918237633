import { useKeycloak } from '@react-keycloak/web';
import { Route, Switch } from 'react-router-dom';
import React from 'react';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuIcon from '@material-ui/icons/Menu';
import Brightness2Icon from '@material-ui/icons/Brightness2';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import payperChainLogo from '../../images/PayperChain.svg';
import LanguageSelector from '../assets/LanguageSelector';
import PaymentDropDown from './PaymentDropDown';
import { ErrorPage } from '../pages/ErrorPage';
import { RoutingItems } from './RoutingItems';
import { useConfig } from '../../common/config';
import { Footer } from './Footer';
import UserMenu from './UserMenu';
import { useStyles } from '../Styles';

interface DashboardProps {
  isDarkMode: boolean;
  themeToggle: () => void;
}

/**
 * This component is the base component which the user sees.
 */
export function Dashboard({ isDarkMode, themeToggle }: DashboardProps): JSX.Element {
  const [userMenuAnchorEl, setUserMenuAnchorEl] = React.useState<HTMLElement | null>(null);
  const [paymentDropDownAnchorEl, setPaymentDropDownAnchorEl] = React.useState<HTMLElement | null>(null);

  const { dashboard, homeRoute, routes } = useConfig();

  const [open, setOpen] = React.useState(true);

  const classes = useStyles();

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  // Wait for auth check
  const k = useKeycloak();
  if (!k.initialized) {
    return <div>Loading...</div>;
  }

  // Draw Dashboard
  return (
    <div className={classes.root}>
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <img className={classes.payperChainLogo} src={payperChainLogo} alt="PayperChain Logo" />
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            {dashboard.title + ' – Dashboard'}
          </Typography>
          <IconButton edge="end" color="inherit" onClick={themeToggle} className={classes.darkModeToggle}>
            {isDarkMode ? <Brightness2Icon /> : <WbSunnyIcon />}
          </IconButton>
          <LanguageSelector />

          {dashboard.allowManagePaymentInformation && (
            <IconButton
              color="inherit"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => setPaymentDropDownAnchorEl(event.currentTarget)}
            >
              <CreditCardIcon />
            </IconButton>
          )}
          <IconButton
            color="inherit"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => setUserMenuAnchorEl(event.currentTarget)}
          >
            <AccountCircleIcon />
          </IconButton>
          <UserMenu anchorEl={userMenuAnchorEl} onClose={() => setUserMenuAnchorEl(null)} />
          <PaymentDropDown anchorEl={paymentDropDownAnchorEl} onClose={() => setPaymentDropDownAnchorEl(null)} />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <RoutingItems />
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          <Grid container spacing={3}>
            {/* Table */}
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Switch>
                  <Route path="/" exact={true}>
                    {homeRoute && <homeRoute.component />}
                  </Route>
                  {routes.map((route, index) => (
                    <Route key={index} path={route.path} exact={route.exact}>
                      <route.component />
                    </Route>
                  ))}
                  <Route path="*">
                    <ErrorPage />
                  </Route>
                </Switch>
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Footer />
          </Box>
        </Container>
      </main>
    </div>
  );
}
