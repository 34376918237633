import React from 'react';
import NumberFormat, { InputAttributes, NumberFormatProps } from 'react-number-format';

interface NumberFormatUnitsProps {
  inputRef: (instance: NumberFormat<InputAttributes> | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  id: string;
}

/**
 * NumberFormat input field that formats the input as units.
 * It can be used with `FormatTextField` by adding following parameter:
 * ```
 * InputProps={{
 *     inputComponent: NumberFormatEurInput as never,
 * }}
 * ```
 * @param props
 */
export function NumberFormatUnitsInput(props: NumberFormatUnitsProps): JSX.Element {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormatUnits
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.id,
            value: values.value
          }
        });
      }}
    />
  );
}

/**
 * Number format text field that formats the value.
 * Usage:
 * ```
 * <NumberFormatUnitsText value={123321.44444444} />
 * ```
 * results in `123,321.4444`
 * @param props
 * @constructor
 */
export function NumberFormatUnitsText(props: NumberFormatProps): JSX.Element {
  return <NumberFormatUnits {...props} displayType="text" />;
}

/**
 * Base function to set consistent unit formatting
 * @param props
 * @constructor
 */
function NumberFormatUnits(props: NumberFormatProps): JSX.Element {
  return <NumberFormat {...props} decimalScale={4} fixedDecimalScale thousandSeparator isNumericString />;
}
