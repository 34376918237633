import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, LinearProgress } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import useInsuranceApi from '../useInsuranceApi';
import FormikForm from '../../../assets/formik/FormikForm';
import FormikTextField from '../../../assets/formik/FormikTextField';
import { NumberFormatEurInput } from '../../../assets/numberformat/NumberFormatEur';
import { NumberFormatUnitsInput } from '../../../assets/numberformat/NumberFormatUnits';
import { InsuranceDto, InsurancePayPerUseDetailsDto, InsuranceUpdateRequest } from '../../../../generated';

/**
 * JSX Element that is an overlay dialog to edit an insurance
 * @param props
 */
export default function PayPerUseDetailsDialog(props: {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  insuranceDto: InsuranceDto;
}): JSX.Element {
  const { promiseInProgress } = usePromiseTracker({ area: 'insurance-dialog', delay: 200 });

  // Trnaslations
  const { t } = useTranslation();
  const closeTxt = t('closeBtn');
  const saveTxt = t('saveBtn');
  const pricePerUnitTxt = t('pricePerUnit');
  const fieldNegativeTxt = t('fieldNegative');
  const annualCapAmountTxt = t('payPerUseDetailsDialog.annualCapAmount');
  const annualBaseAmountTxt = t('payPerUseDetailsDialog.annualBaseAmount');
  const annualExpectedAmountTxt = t('payPerUseDetailsDialog.annualExpectedAmount');
  const annualExpectedActivityTxt = t('payPerUseDetailsDialog.annualExpectedActivity');
  const editInsurancePPUDetailsTxt = t('payPerUseDetailsDialog.editInsurancePPUDetails');

  const { handleUpdate } = useInsuranceApi();

  const mapToInsuranceUpdateRequest = function (
    insurancePayPerUseDetailsDto: InsurancePayPerUseDetailsDto
  ): InsuranceUpdateRequest {
    return {
      insuranceStatus: props.insuranceDto.insuranceStatus,
      insuranceDetails: props.insuranceDto.insuranceDetails,
      machineDetails: props.insuranceDto.machineDetails,
      paymentDetails: props.insuranceDto.paymentDetails,
      payPerUseDetails: insurancePayPerUseDetailsDto
    };
  };

  const validationSchema = yup.object({
    annualExpectedAmount: yup.number().min(0.0, fieldNegativeTxt),
    annualBaseAmount: yup.number().min(0.0, fieldNegativeTxt),
    annualCapAmount: yup.number().min(0.0, fieldNegativeTxt),
    annualExpectedActivity: yup.number().min(0.0, fieldNegativeTxt),
    pricePerUnit: yup.number().min(0.0, fieldNegativeTxt)
  });

  const formik = useFormik({
    initialValues: {} as InsurancePayPerUseDetailsDto,
    validationSchema: validationSchema,
    onSubmit: (values) =>
      handleUpdate(props.insuranceDto.insuranceExternalId, mapToInsuranceUpdateRequest(values), props.onSave)
  });

  // Initialize form
  useEffect(() => {
    if (!props.open) return;
    formik.resetForm();

    const initialize = async function () {
      if (props.insuranceDto) {
        await formik.setValues(props.insuranceDto.payPerUseDetails);
        await formik.validateForm();
      }
    };

    trackPromise(initialize(), 'insurance-dialog');

    // Adding 'formik' to dependencies creates an infinite loop as formik changes every render
  }, [props.open, props.insuranceDto]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
      {/* Without div the progress bar is not visible when scrollbar is shown */}
      {promiseInProgress && (
        <div>
          <LinearProgress />
        </div>
      )}
      <DialogTitle id="form-dialog-title">{editInsurancePPUDetailsTxt}</DialogTitle>
      <FormikForm formik={formik}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormikTextField
                formik={formik}
                id="annualExpectedAmount"
                variant="outlined"
                fullWidth
                label={annualExpectedAmountTxt}
                InputProps={{
                  inputComponent: NumberFormatEurInput as never
                }}
                disabled={promiseInProgress}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                formik={formik}
                id="annualBaseAmount"
                variant="outlined"
                fullWidth
                label={annualBaseAmountTxt}
                InputProps={{
                  inputComponent: NumberFormatEurInput as never
                }}
                disabled={promiseInProgress}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                formik={formik}
                id="annualCapAmount"
                variant="outlined"
                fullWidth
                label={annualCapAmountTxt}
                InputProps={{
                  inputComponent: NumberFormatEurInput as never
                }}
                disabled={promiseInProgress}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                formik={formik}
                id="annualExpectedActivity"
                variant="outlined"
                fullWidth
                label={annualExpectedActivityTxt}
                InputProps={{
                  inputComponent: NumberFormatUnitsInput as never
                }}
                disabled={promiseInProgress}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                formik={formik}
                id="pricePerUnit"
                variant="outlined"
                fullWidth
                label={pricePerUnitTxt}
                InputProps={{
                  inputComponent: NumberFormatEurInput as never
                }}
                disabled={promiseInProgress}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={props.onClose}>
            {closeTxt}
          </Button>
          <Button variant="contained" color="primary" type="submit" disabled={promiseInProgress || !formik.isValid}>
            {saveTxt}
          </Button>
        </DialogActions>
      </FormikForm>
    </Dialog>
  );
}
