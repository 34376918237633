import { trackPromise } from 'react-promise-tracker';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import AddBoxIcon from '@material-ui/icons/AddBox';
import PaymentIcon from '@material-ui/icons/Payment';
import { ListItemIcon, ListItemText, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';

import { useConfig } from '../../common/config';
import { getMemberApi } from '../../common/keycloak';
import PaymentInformationDialog from './PaymentInformationDialog';
import { MemberDto, PaymentInformationDto } from '../../generated';
import ExtraPaymentInformationDialog from './ExtraPaymentInformationDialog';
import ShowByRole from '../assets/ShowByRole';
import { hasRole } from '../../common/keycloak';
import useNotifications from '../assets/useNotifications';
import { useStyles } from '../Styles';

export default function PaymentDropDown(props: { anchorEl: HTMLElement | null; onClose: () => void }): JSX.Element {
  const [paymentInformationDialogOpen, setPaymentInformationDialogOpen] = React.useState(false);
  const [ExtraPaymentInformationDialogOpen, setExtraPaymentInformationDialogOpen] = React.useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [reloadDialog, setreloadDialog] = useState(false);
  const [members, setMembers] = useState({} as MemberDto);
  const [paymentInfoForEdit, setPaymentInfoForEdit] = useState({} as PaymentInformationDto);
  const [paymentIndex, setPaymentIndex] = useState<number>(0);
  const isGuest = hasRole(['guest']);
  const { showError } = useNotifications();

  const classes = useStyles();

  const { dashboard } = useConfig();
  // Translations
  const { t } = useTranslation();
  const dropdownTitle = t('paymentDropDown.title');
  const addAccountHover = t('paymentDropDown.addAccountHover');
  const editAccountHover = t('paymentDropDown.editAccountHover');
  const guestNotAllowedToAddAccount = t('paymentDropDown.guestNotAllowedToAddAccount');
  const defalut = t('paymentDropDown.defalut');

  const editPaymentHandler = (paymentForEdit: PaymentInformationDto, index: number) => {
    setPaymentInfoForEdit(paymentForEdit);
    setIsEditing(true);
    setPaymentIndex(index);
    setExtraPaymentInformationDialogOpen(true);
  };

  const addPaymentHandler = (members: MemberDto) => {
    if (isGuest) {
      showError(guestNotAllowedToAddAccount);
      return;
    }
    setIsEditing(false);
    if (!members.paymentInformation) {
      setPaymentInformationDialogOpen(true);
    } else {
      if (!members.extraPaymentInformation || members.extraPaymentInformation.length === 0) {
        // If extraPaymentInformation is undefined or empty, add a new empty object
        setMembers((prevMembers) => ({
          ...prevMembers,
          extraPaymentInformation: [{} as PaymentInformationDto]
        }));
      } else if (!members.extraPaymentInformation.some((info) => Object.keys(info).length === 0)) {
        // If extraPaymentInformation does not contain an empty object, add one
        setMembers((prevMembers) => ({
          ...prevMembers,
          extraPaymentInformation: [...(prevMembers.extraPaymentInformation ?? []), {} as PaymentInformationDto]
        }));
      }

      setExtraPaymentInformationDialogOpen(true);
    }
  };

  useEffect(() => {
    const loadMember = async () => {
      const memberApi = await getMemberApi();
      const memberData = await memberApi.getMember();
      setMembers(memberData);
    };

    trackPromise(loadMember(), 'payment-drop-down');
  }, [reloadDialog]);

  const dialogChangeHandler = () => {
    setreloadDialog((prevState) => !prevState);
  };

  return (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={props.anchorEl}
        keepMounted
        open={Boolean(props.anchorEl)}
        onClose={props.onClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <MenuItem disabled={false} className={classes.accountItem}>
          <ListItemIcon className={classes.accountItemGrid}>
            <PaymentIcon className={classes.paymentIcon} />
            <Typography className={classes.accountTitle}>{dropdownTitle}</Typography>
          </ListItemIcon>
          <ShowByRole roles={['user']}>
            <Tooltip title={addAccountHover}>
              <ListItemIcon onClick={() => addPaymentHandler(members)} className={classes.addPayment}>
                <AddBoxIcon className={classes.Icon} />
              </ListItemIcon>
            </Tooltip>
          </ShowByRole>
        </MenuItem>
        {!isGuest && members.paymentInformation && (
          <Tooltip title={editAccountHover}>
            <MenuItem
              key={'DefaultPaymentInfo'}
              disabled={false}
              onClick={() => setPaymentInformationDialogOpen(true)}
              className={classes.MenuItem}
            >
              <ListItemText>{`${members.paymentInformation.displayName} (${defalut})`}</ListItemText>

              <ListItemIcon className={classes.listItemIcon}>
                <EditIcon className={classes.Icon} />
              </ListItemIcon>
            </MenuItem>
          </Tooltip>
        )}
        {!isGuest &&
          members.extraPaymentInformation &&
          members.extraPaymentInformation.length > 0 &&
          members.extraPaymentInformation?.map((paymentInfo, index) => {
            if (Object.keys(paymentInfo).length > 0) {
              return (
                <Tooltip title={editAccountHover}>
                  <MenuItem
                    key={index}
                    disabled={false}
                    onClick={() => editPaymentHandler(paymentInfo, index)}
                    className={classes.MenuItem}
                  >
                    <>
                      <ListItemText>{paymentInfo.displayName}</ListItemText>
                      <ListItemIcon className={classes.listItemIcon}>
                        <EditIcon className={classes.Icon} />
                      </ListItemIcon>
                    </>
                  </MenuItem>
                </Tooltip>
              );
            }
            return null;
          })}
      </Menu>
      {dashboard.allowManagePaymentInformation && (
        <PaymentInformationDialog
          members={members}
          onChange={dialogChangeHandler}
          open={paymentInformationDialogOpen}
          onClose={() => setPaymentInformationDialogOpen(false)}
        />
      )}
      {dashboard.allowManagePaymentInformation && (
        <ExtraPaymentInformationDialog
          members={members}
          paymentInfoForEdit={paymentInfoForEdit}
          paymentIndex={paymentIndex}
          onChange={dialogChangeHandler}
          open={ExtraPaymentInformationDialogOpen}
          isEditing={isEditing}
          onClose={() => setExtraPaymentInformationDialogOpen(false)}
        />
      )}
    </div>
  );
}
