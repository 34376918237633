import React from 'react';
import { useTranslation } from 'react-i18next';
import MaterialTable, { Column } from 'material-table';
import { CircularProgress, Paper } from '@material-ui/core';

import ColorChip from '../../assets/ColorChip';
import { PaymentDetails } from './PaymentDetails';
import { PaymentDto, PaymentStatusEnum } from '../../../generated';
import { MaterialTableIcons } from '../../assets/MaterialTableIcons';
import { NumberFormatEurText } from '../../assets/numberformat/NumberFormatEur';
import { NumberFormatUnitsText } from '../../assets/numberformat/NumberFormatUnits';
import { dateComparer, formatCordaX500Name, formatDateTime } from '../../../common/format';

export function PaymentsTable(props: { paymentsData: PaymentDto[]; promiseInProgress: boolean }): JSX.Element {
  // Trnaslations
  const { t } = useTranslation();
  const rowTxt = t('row');
  const unitTxt = t('unit');
  const searchTxt = t('search');
  const amountTxt = t('amount');
  const containsTxt = t('contains');
  const selectDateTxt = t('selectDate');
  const largerThanTxt = t('largerThan');
  const timestampTxt = t('timestamps');
  const lastUpdateTxt = t('lastUpdate');
  const machineOwnerTxt = t('machineOwner');
  const machineUserTxt = t('machineUser');
  const machineNameTxt = t('machineName');
  const pendingTxt = t('paymentsTable.pending');
  const initiatedTxt = t('paymentsTable.initiated');
  const confirmedTxt = t('paymentsTable.confirmed');
  const noRecordsToDisplayTxt = t('noRecordsToDisplay');

  /**
   * Define the columns including format/type and filtering
   */
  const ColumnPaymentTimestamp: Column<PaymentDto> = {
    title: timestampTxt,
    field: 'created',
    type: 'date',
    render: (data: PaymentDto) => formatDateTime(data.created),
    customFilterAndSearch: (term: string, data: PaymentDto) => dateComparer(term, data.created),
    filterPlaceholder: selectDateTxt,
    defaultSort: 'desc'
  };

  const ColumnMachineOwner: Column<PaymentDto> = {
    title: machineOwnerTxt,
    field: 'machineOwner',
    render: (data: PaymentDto) => formatCordaX500Name(data.machineOwner),
    filterPlaceholder: containsTxt
  };

  const ColumnMachineUser: Column<PaymentDto> = {
    title: machineUserTxt,
    field: 'machineUser',
    render: (data: PaymentDto) => formatCordaX500Name(data.machineUser),
    filterPlaceholder: containsTxt
  };

  const ColumnMachineName: Column<PaymentDto> = {
    title: machineNameTxt,
    field: 'machineName',
    filterPlaceholder: containsTxt
  };

  const ColumnTotalUnits: Column<PaymentDto> = {
    title: unitTxt,
    field: 'totalUnits',
    headerStyle: { textAlign: 'right' },
    cellStyle: { textAlign: 'right' },
    render: (data: PaymentDto) => <NumberFormatUnitsText value={data.totalUnits} />,
    customFilterAndSearch: (term: number, rowData: PaymentDto) => term <= rowData.totalUnits,
    filterPlaceholder: largerThanTxt
  };

  const ColumnTotalAmount: Column<PaymentDto> = {
    title: amountTxt,
    field: 'totalAmount',
    headerStyle: { textAlign: 'right' },
    cellStyle: { textAlign: 'right' },
    render: (data: PaymentDto) => <NumberFormatEurText value={data.totalAmount} />,
    customFilterAndSearch: (term: number, rowData: PaymentDto) => term <= rowData.totalAmount,
    filterPlaceholder: largerThanTxt
  };

  function PaymentStatusChip(props: { paymentStatus: PaymentStatusEnum }) {
    let chipColor: 'green' | 'orange' | 'grey' = 'grey';
    let paymentStatusLabel = '';

    switch (props.paymentStatus) {
      case PaymentStatusEnum.Initiated: {
        chipColor = 'grey';
        paymentStatusLabel = initiatedTxt;
        break;
      }
      case PaymentStatusEnum.Pending: {
        chipColor = 'orange';
        paymentStatusLabel = pendingTxt;
        break;
      }
      case PaymentStatusEnum.Confirmed: {
        chipColor = 'green';
        paymentStatusLabel = confirmedTxt;
        break;
      }
    }

    return <ColorChip chipColor={chipColor} size="small" label={paymentStatusLabel} />;
  }

  const ColumnPaymentStatus: Column<PaymentDto> = {
    title: 'Status',
    field: 'paymentStatus',
    headerStyle: { textAlign: 'center' },
    cellStyle: {
      textAlign: 'center'
    },
    render: (data: PaymentDto) => <PaymentStatusChip paymentStatus={data.paymentStatus} />,
    filterPlaceholder: containsTxt
  };

  const ColumnPaymentLastUpdate: Column<PaymentDto> = {
    title: lastUpdateTxt,
    field: 'updated',
    type: 'date',
    render: (data: PaymentDto) => formatDateTime(data.updated),
    customFilterAndSearch: (term: string, data: PaymentDto) => dateComparer(term, data.updated),
    filterPlaceholder: selectDateTxt
  };

  const columnDefs: Column<PaymentDto>[] = [
    ColumnPaymentTimestamp,
    ColumnMachineOwner,
    ColumnMachineUser,
    ColumnMachineName,
    ColumnTotalUnits,
    ColumnTotalAmount,
    ColumnPaymentStatus,
    ColumnPaymentLastUpdate
  ];

  return (
    <MaterialTable
      localization={{
        pagination: {
          labelRowsSelect: rowTxt
        },
        toolbar: {
          searchPlaceholder: searchTxt
        },
        body: {
          emptyDataSourceMessage: props.promiseInProgress ? <CircularProgress /> : noRecordsToDisplayTxt
        }
      }}
      components={{
        Container: (props) => <Paper {...props} elevation={0} />
      }}
      icons={MaterialTableIcons()}
      columns={columnDefs}
      data={props.paymentsData}
      detailPanel={(row) => {
        return <PaymentDetails payment={row} />;
      }}
      options={{
        filtering: true,
        sorting: true,
        showTitle: false
      }}
    />
  );
}
