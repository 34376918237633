import { useTranslation } from 'react-i18next';
import React from 'react';
import { Edit } from '@material-ui/icons';
import MaterialTable, { Column } from 'material-table';
import { CircularProgress, IconButton, Paper } from '@material-ui/core';

import { useConfig } from '../../../common/config';
import { InsuranceDto } from '../../../generated';
import { InsuranceDetails } from './InsuranceDetails';
import InsuranceStatusChip from './InsuranceStatusChip';
import { InsuranceDialogState } from './InsuranceDialog';
import { MaterialTableIcons } from '../../assets/MaterialTableIcons';
import { hasRole } from '../../../common/keycloak';
import { dateComparer, formatCordaX500Name, formatDateTime } from '../../../common/format';

export function InsurancesTable(props: {
  insurancesData: InsuranceDto[];
  promiseInProgress: boolean;
  setEditInsuranceDialog: (open: { open: InsuranceDialogState; insurance: InsuranceDto }) => void;
}): JSX.Element {
  const { insurancePage } = useConfig();
  const isGuest = hasRole(['guest']);
  // Trnaslations
  const { t } = useTranslation();
  const rowTxt = t('row');
  const editTxt = t('edit');
  const searchTxt = t('search');
  const createdTxt = t('created');
  const updatedTxt = t('updated');
  const insurerTxt = t('insurer');
  const containsTxt = t('contains');
  const selectDateTxt = t('selectDate');
  const machineNameTxt = t('machineName');
  const noRecordsTxt = t('noRecordsToDisplay');
  const counterPartyTxt = t('insurance.counterParty');
  const insuranceNumberTxt = t('insurance.insuranceNumber');

  const ColumnExternalId: Column<InsuranceDto> = {
    title: insuranceNumberTxt,
    field: 'insuranceExternalId',
    filterPlaceholder: containsTxt,
    defaultSort: 'asc'
  };

  const ColumnMachineName: Column<InsuranceDto> = {
    title: machineNameTxt,
    field: 'machineName',
    filterPlaceholder: containsTxt
  };

  const ColumnInsurer: Column<InsuranceDto> = {
    title: insurerTxt,
    field: 'insurer',
    render: (data: InsuranceDto) => formatCordaX500Name(data.insurer),
    filterPlaceholder: containsTxt
  };

  const ColumnCounterparty: Column<InsuranceDto> = {
    title: counterPartyTxt,
    field: 'counterparty',
    render: (data: InsuranceDto) => formatCordaX500Name(data.counterparty),
    filterPlaceholder: containsTxt
  };

  const ColumnStatus: Column<InsuranceDto> = {
    title: 'Status',
    field: 'insuranceStatus',
    filterPlaceholder: containsTxt,
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
    render: (data: InsuranceDto) => <InsuranceStatusChip insuranceStatus={data.insuranceStatus} size="small" />
  };

  const ColumnCreated: Column<InsuranceDto> = {
    title: createdTxt,
    field: 'created',
    type: 'date',
    render: (data: InsuranceDto) => formatDateTime(data.created),
    customFilterAndSearch: (term: string, data: InsuranceDto) => dateComparer(term, data.created),
    filterPlaceholder: selectDateTxt
  };

  const ColumnUpdated: Column<InsuranceDto> = {
    title: updatedTxt,
    field: 'updated',
    type: 'date',
    render: (data: InsuranceDto) => formatDateTime(data.updated),
    customFilterAndSearch: (term: string, data: InsuranceDto) => dateComparer(term, data.updated),
    filterPlaceholder: selectDateTxt
  };

  const ColumnEdit: Column<InsuranceDto> = {
    title: editTxt,
    sorting: false,
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
    render: (data: InsuranceDto) => (
      <IconButton
        onClick={() => {
          props.setEditInsuranceDialog({ open: InsuranceDialogState.InsuranceBasicDialog, insurance: data });
        }}
      >
        <Edit />
      </IconButton>
    )
  };

  const columnDefs: Column<InsuranceDto>[] = [
    ColumnExternalId,
    ColumnMachineName,
    ColumnInsurer,
    ColumnCounterparty,
    ColumnStatus,
    ColumnCreated,
    ColumnUpdated
  ];

  // Add edit button
  if (!isGuest && insurancePage.canEditInsurances) {
    columnDefs.push(ColumnEdit);
  }

  return (
    <MaterialTable
      localization={{
        pagination: {
          labelRowsSelect: rowTxt
        },
        toolbar: {
          searchPlaceholder: searchTxt
        },
        body: {
          emptyDataSourceMessage: props.promiseInProgress ? <CircularProgress /> : noRecordsTxt
        }
      }}
      components={{
        Container: (props) => <Paper {...props} elevation={0} />
      }}
      icons={MaterialTableIcons()}
      columns={columnDefs}
      data={props.insurancesData}
      detailPanel={(row) => {
        return <InsuranceDetails insurance={row} setEditInsuranceDialog={props.setEditInsuranceDialog} />;
      }}
      options={{
        filtering: true,
        sorting: true,
        showTitle: false
      }}
    />
  );
}
