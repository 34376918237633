import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Edit } from '@material-ui/icons';
import { CircularProgress, IconButton, List, ListItem, ListItemText, Paper } from '@material-ui/core';

import { NumberFormatEurText } from '../../assets/numberformat/NumberFormatEur';
import { MaterialTableIcons } from '../../assets/MaterialTableIcons';
import { formatCordaX500Name } from '../../../common/format';
import { getNetworkApi } from '../../../common/keycloak';
import MaterialTable, { Column } from 'material-table';
import { MachineDto } from '../../../generated';
import { MachineDetails } from './MachineDetails';
import { useConfig } from '../../../common/config';
import ShowByRole from '../../assets/ShowByRole';

export function MachinesTable(props: {
  machinesData: MachineDto[];
  promiseInProgress: boolean;
  setEditMachineDialog: (open: { open: boolean; machine: MachineDto }) => void;
}): JSX.Element {
  const { promiseInProgress } = usePromiseTracker({ area: 'my-identity', delay: 200 });
  const [myIdentity, setMyIdentity] = useState('');
  const { machinesPage } = useConfig();
  // Trnaslations
  const { t } = useTranslation();
  const rowTxt = t('row');
  const editTxt = t('edit');
  const searchTxt = t('search');
  const insurersTxt = t('insurers');
  const containsTxt = t('contains');
  const largerThanTxt = t('largerThan');
  const machineOwnerTxt = t('machineOwner');
  const machineUserTxt = t('machineUser');
  const machineNameTxt = t('machineName');
  const pricePerUnitTxt = t('pricePerUnit');
  const paymentProviderTxt = t('paymentProvider');
  const paymentThresholdTxt = t('paymentThreshold');
  const noRecordsToDisplayTxt = t('noRecordsToDisplay');

  // Get my identity to show edit buttons only when machineOwner of machine is equal to it
  useEffect(() => {
    if (!machinesPage.canEditMachines) return;

    const getIdentity = async function () {
      const networkApi = await getNetworkApi();
      const currentNode = await networkApi.getActiveNode();

      setMyIdentity(formatCordaX500Name(currentNode.identity));
    };

    trackPromise(getIdentity(), 'my-identity');
  }, []);

  /**
   * Define the columns including format/type and filtering
   */
  const ColumnMachineOwner: Column<MachineDto> = {
    title: machineOwnerTxt,
    field: 'machineOwner',
    render: (rowData: MachineDto) => formatCordaX500Name(rowData.machineOwner),
    filterPlaceholder: containsTxt
  };

  const ColumnMachineUser: Column<MachineDto> = {
    title: machineUserTxt,
    field: 'machineUser',
    render: (rowData: MachineDto) => formatCordaX500Name(rowData.machineUser),
    filterPlaceholder: containsTxt
  };

  const ColumnMachineName: Column<MachineDto> = {
    title: machineNameTxt,
    field: 'machineName',
    filterPlaceholder: containsTxt,
    defaultSort: 'asc'
  };

  const ColumnPricePerUnit: Column<MachineDto> = {
    title: pricePerUnitTxt,
    field: 'pricePerUnit',
    headerStyle: { textAlign: 'right' },
    cellStyle: { textAlign: 'right' },
    render: (rowData: MachineDto) => <NumberFormatEurText value={rowData.pricePerUnit} />,
    customFilterAndSearch: (term: number, rowData: MachineDto) => term <= rowData.pricePerUnit,
    filterPlaceholder: largerThanTxt
  };

  const ColumnPaymentThreshold: Column<MachineDto> = {
    title: paymentThresholdTxt,
    field: 'paymentThreshold',
    headerStyle: { textAlign: 'right' },
    cellStyle: { textAlign: 'right' },
    render: (rowData: MachineDto) => <NumberFormatEurText value={rowData.paymentThreshold} />,
    customFilterAndSearch: (term: number, rowData: MachineDto) => term <= (rowData.paymentThreshold ?? 0),
    filterPlaceholder: largerThanTxt
  };

  const ColumnPaymentProvider: Column<MachineDto> = {
    title: paymentProviderTxt,
    field: 'paymentProvider',
    render: (rowData: MachineDto) => formatCordaX500Name(rowData.paymentProvider),
    filterPlaceholder: containsTxt
  };

  const ColumnInsurers: Column<MachineDto> = {
    title: insurersTxt,
    field: 'insurers',
    render: (rowData: MachineDto) => (
      <List dense disablePadding>
        {rowData.insurers.map((insurer, index) => {
          return (
            <ListItem dense disableGutters key={index + 10000}>
              <ListItemText>{formatCordaX500Name(insurer)}</ListItemText>
            </ListItem>
          );
        })}
      </List>
    ),
    filterPlaceholder: containsTxt
  };

  // Show Edit button only when myIdentity equals machineOwner of the machine
  const ColumnEditMachine: Column<MachineDto> = {
    title: editTxt,
    sorting: false,
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
    render: function (rowData: MachineDto) {
      if (myIdentity != formatCordaX500Name(rowData.machineOwner)) return;

      return (
        <>
          <ShowByRole roles={['user']}>
            <IconButton
              onClick={() => {
                props.setEditMachineDialog({ open: true, machine: rowData });
              }}
            >
              <Edit />
            </IconButton>
          </ShowByRole>
        </>
      );
    }
  };

  const columnDefs: Column<MachineDto>[] = [
    ColumnMachineName,
    ColumnPricePerUnit,
    ColumnPaymentThreshold,
    ColumnMachineOwner,
    ColumnMachineUser,
    ColumnPaymentProvider,
    ColumnInsurers
  ];

  // Add edit button
  if (machinesPage.canEditMachines) {
    columnDefs.push(ColumnEditMachine);
  }

  return (
    <MaterialTable
      localization={{
        pagination: {
          labelRowsSelect: rowTxt
        },
        toolbar: {
          searchPlaceholder: searchTxt
        },
        body: {
          emptyDataSourceMessage:
            props.promiseInProgress || promiseInProgress ? <CircularProgress /> : noRecordsToDisplayTxt
        }
      }}
      components={{
        Container: (props) => <Paper {...props} elevation={0} />
      }}
      icons={MaterialTableIcons()}
      columns={columnDefs}
      data={promiseInProgress ? [] : props.machinesData}
      detailPanel={(row) => {
        return <MachineDetails machine={row} />;
      }}
      options={{
        filtering: true,
        sorting: true,
        showTitle: false
      }}
    />
  );
}
