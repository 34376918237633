import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: 'none'
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  fixedHeight: {
    height: 240
  },

  payperChainLogo: {
    height: 50,
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: '#b3b3b3',
    borderRadius: '20%',
    marginRight: 10,
    backgroundColor: 'white'
  },
  paperBox: {
    alignItems: 'flex-start',
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 500
  },
  dzBankLogo: {
    margin: 'auto',
    display: 'block',
    maxWidth: '80%',
    maxHeight: '80%'
  },
  Table: {
    height: 'auto',
    width: '100%'
  },
  summaryFooter: {
    fontWeight: 'bold',
    color: theme.palette.type === 'dark' ? 'white' : 'black'
  },
  papertable: {
    padding: theme.spacing(2),
    elevation: '3',
    display: 'flex',
    overflow: 'visible',
    flexDirection: 'column',
    margin: 'auto',
    width: '100%'
  },
  divTable: {
    display: 'flex',
    padding: theme.spacing(1)
  },
  buttonRight: {
    'text-align': 'right'
  },
  seeMore: {
    marginTop: theme.spacing(3)
  },

  spinner: {
    width: '100%',
    height: '100%',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  },

  MenuItem: {
    cursor: 'pointer',
    padding: '8px 0 8px 16px',
    margin: '8px 24px',
    border: `1px solid #b3b3b3`,
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    },
    '&:active': {
      backgroundColor: 'transparent'
    },
    '&:last-child': {
      marginBottom: '16px'
    }
  },

  MenuItemModified: {
    '&:first-child': {
      marginTop: '16px'
    }
  },

  accountItem: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&:active': {
      backgroundColor: 'transparent'
    },
    cursor: 'default',
    display: 'flex',
    justifyContent: 'space-around'
  },

  accountTitle: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginLeft: '8px'
  },

  accountItemGrid: {
    display: 'flex',
    alignItems: 'center'
  },

  listItemIcon: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer'
  },

  Icon: {
    cursor: 'pointer'
  },

  paymentIcon: {
    size: '20px',
    marginLeft: '5px'
  },

  addPayment: {
    display: 'flex',
    justifyContent: 'flex-end'
  },

  styledField: {
    width: '100%',
    padding: '18.5px 14px',
    border: '1px solid #ced4da',
    borderRadius: '4px',
    fontSize: '16px',
    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
  },

  fieldError: {
    color: '#ff1744',
    margin: '3px 14px 0 14px'
  },

  turnRed: {
    color: '#ff1744',
    border: '1px solid #ff1744'
  },
  gridItem: {
    minWidth: '300px'
  },

  tabsContainer: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-evenly',
      marginTop: '15px'
    },
    '& .MuiTabs-indicator': {
      backgroundColor: 'red'
    },
    '& .MuiTab-root': {
      color: theme.palette.primary.main,
      fontWeight: 'bold'
    }
  },

  pmFormLabel: {
    '& .MuiFormLabel-root': {
      fontSize: '12px',
      margin: '4px 0 6px'
    },
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
      flexWrap: 'nowrap'
    },
    '& .MuiTypography-root': {
      fontSize: '13px',
      lineHeight: '16px'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '23px'
    }
  },

  darkModeToggle: {
    marginRight: '16px'
  },

  toggleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  toggleButtonGroup: {
    border: '1px solid #3f51b5 !important',
    borderRadius: '20px !important',
    overflow: 'hidden',
    marginRight: '16px'
  },

  toggleButton: {
    border: 'none !important',
    fontSize: '14px !important',
    padding: '1px 16px !important',
    textTransform: 'none',
    color: '#a8a8a8 !important',
    '&.Mui-selected': {
      backgroundColor: '#3f51b5 !important',
      color: '#fff !important'
    },
    '&:not(:first-child)': {
      borderLeft: '1px solid #3f51b5 !important'
    }
  }
}));
