import React from 'react';
import PropTypes from 'prop-types';
import { hasRole } from '../../common/keycloak';

interface ShowByRoleProp {
  roles: string[];
  children: React.ReactNode;
}

export default function ShowByRole({ roles, children }: ShowByRoleProp): JSX.Element | null {
  return hasRole(roles) ? <>{children}</> : null;
}

ShowByRole.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired
};
