import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { Cached } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import { Grid } from '@material-ui/core';

import { MachineDto, ResponseError } from '../../../generated';
import useNotifications from '../../assets/useNotifications';
import { getMachinesApi } from '../../../common/keycloak';
import { useConfig } from '../../../common/config';
import { MachinesTable } from './MachinesTable';
import MachineDialog from './MachineDialog';
import { Title } from '../../assets/Title';
import { hasRole } from '../../../common/keycloak';
import { useStyles } from '../../Styles';

/**
 * Definition of the component that displays price and invoice information for
 * every machine and lets the user change these parameters (depending on the
 * user role).
 */
export function MachinesPage(): JSX.Element {
  const classes = useStyles();
  const { promiseInProgress } = usePromiseTracker({ area: 'machines', delay: 200 });
  const { showError } = useNotifications();
  const [machinesData, setMachinesData] = useState(new Array<MachineDto>());
  const { machinesPage } = useConfig();
  const isGuest = hasRole(['guest']);

  // Trnaslations
  const { t } = useTranslation();
  const reloadTxt = t('reload');
  const machinesTxt = t('machines');
  const userUnauthorizedTxt = t('userUnauthorized');
  const newMachineTxt = t('machinesPage.newMachine');
  const errorFetchingDataTxt = t('errorFetchingData');

  interface EditMachineDialogData {
    open: boolean;
    machine?: MachineDto;
  }

  const [editMachineDialog, setEditMachineDialog] = React.useState({
    open: false
  } as EditMachineDialogData);

  const [reloadData, setReloadData] = useState(0);

  // Relaod
  const reload = () => {
    setReloadData(reloadData + 1);
  };

  /** fetch data */
  useEffect(() => {
    const loadMachineData = async () => {
      const api = await getMachinesApi();
      try {
        const result = await api.getMachines();
        setMachinesData(result);
      } catch (error) {
        if (error instanceof ResponseError) {
          if (error.response && error.response.status === 401) {
            showError(userUnauthorizedTxt);
          } else {
            showError(error.message);
          }
        } else {
          showError(errorFetchingDataTxt);
        }
      }
    };
    trackPromise(loadMachineData(), 'machines');
  }, [reloadData, showError]);

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} style={{ flex: 1 }}>
          <Title>{machinesTxt}</Title>
        </Grid>
        <Grid item className={classes.buttonRight}>
          <Grid container spacing={2} justify="flex-end" direction="row">
            <Grid item>
              <Button variant="outlined" color="primary" startIcon={<Cached />} onClick={reload}>
                {reloadTxt}
              </Button>
            </Grid>
            {!isGuest && machinesPage.canEditMachines && (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => setEditMachineDialog({ open: true, machine: undefined })}
                >
                  {newMachineTxt}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <MachinesTable
        machinesData={machinesData}
        promiseInProgress={promiseInProgress}
        setEditMachineDialog={setEditMachineDialog}
      />
      {machinesPage.canEditMachines && (
        <MachineDialog
          open={editMachineDialog.open}
          machineDto={editMachineDialog.machine}
          onClose={() => setEditMachineDialog({ open: false })}
          onSave={() => {
            setEditMachineDialog({ open: false });
            reload();
          }}
        />
      )}
    </React.Fragment>
  );
}
