import React from 'react';

import { InsuranceDto } from '../../../generated';

import InsuranceBasicDialog from './dialogs/InsuranceBasicDialog';
import InsuranceDetailsDialog from './dialogs/InsuranceDetailsDialog';
import MachineDetailsDialog from './dialogs/MachineDetailsDialog';
import PaymentDetailsDialog from './dialogs/PaymentDetailsDialog';
import PayPerUseDetailsDialog from './dialogs/PayPerUseDetailsDialog';

/**
 * Enum to control which dialog is open
 */
export enum InsuranceDialogState {
  None,
  InsuranceBasicDialog,
  InsuranceDetailsDialog,
  MachineDetailsDialog,
  PaymentDetailsDialog,
  PayPerUseDetailsDialog
}

/**
 * JSX Element that is an overlay dialog to edit an insurance
 * @param props
 */
export default function InsuranceDialog(props: {
  open: InsuranceDialogState;
  onClose: () => void;
  onSave: () => void;
  insuranceDto?: InsuranceDto;
}): JSX.Element {
  return (
    <>
      <InsuranceBasicDialog
        open={props.open == InsuranceDialogState.InsuranceBasicDialog}
        onClose={props.onClose}
        onSave={props.onSave}
        insuranceDto={props.insuranceDto}
      />
      {props.insuranceDto && (
        <>
          <InsuranceDetailsDialog
            open={props.open == InsuranceDialogState.InsuranceDetailsDialog}
            onClose={props.onClose}
            onSave={props.onSave}
            insuranceDto={props.insuranceDto}
          />
          <MachineDetailsDialog
            open={props.open == InsuranceDialogState.MachineDetailsDialog}
            onClose={props.onClose}
            onSave={props.onSave}
            insuranceDto={props.insuranceDto}
          />
          <PaymentDetailsDialog
            open={props.open == InsuranceDialogState.PaymentDetailsDialog}
            onClose={props.onClose}
            onSave={props.onSave}
            insuranceDto={props.insuranceDto}
          />
          <PayPerUseDetailsDialog
            open={props.open == InsuranceDialogState.PayPerUseDetailsDialog}
            onClose={props.onClose}
            onSave={props.onSave}
            insuranceDto={props.insuranceDto}
          />
        </>
      )}
    </>
  );
}
