import React from 'react';
import { useTranslation } from 'react-i18next';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import { Title } from '../../assets/Title';
/**
 * Definition of the about view component.
 */
export function AboutPage(): JSX.Element {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Title>{t('aboutPage.title')}</Title>
      <br />
      <Typography variant="body1">{t('aboutPage.security')}</Typography>
      <br />
      <Typography variant="body2">{t('aboutPage.flexibility')}</Typography>
      <Typography variant="body2">{t('aboutPage.investment')}</Typography>
      <p>
        <Link href="mailto:info@payperchain.com"> {t('aboutPage.contactUs')}</Link>
      </p>
      <Typography variant="body2">
        <Link href="https://www.payperchain.com/">PayperChain GmbH</Link> | Vor dem Langen Loh 6 | 35075
        Gladenbach-Mornshausen
      </Typography>
      <br />
      <br />
    </React.Fragment>
  );
}
