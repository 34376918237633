import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { LinearProgress } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { getMemberApi } from '../../common/keycloak';
import FormikForm from '../assets/formik/FormikForm';
import useNotifications from '../assets/useNotifications';
import FormikTextField from '../assets/formik/FormikTextField';
import PaymentInfoDeleteEditDialog from './PaymentInfoDeleteEditDialog';
import { MemberDto, MemberRequest, PaymentInformationDto } from '../../generated';

/**
 * JSX Element that is an overlay dialog to edit the participants own payment information
 * @param props
 */
const ExtraPaymentInformationDialog = (props: {
  open: boolean;
  isEditing: boolean;
  onChange: () => void;
  onClose: () => void;
  members: MemberDto;
  paymentInfoForEdit: PaymentInformationDto;
  paymentIndex: number;
}): JSX.Element => {
  const { promiseInProgress } = usePromiseTracker({ area: 'payment-information-dialog', delay: 200 });
  const { showError, showSuccess } = useNotifications();

  const [deleteAlertDialogIsOpen, setDeleteAlertDialogIsOpen] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isDeleteable, setIsDeleteable] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);

  // Trnaslations
  const { t } = useTranslation();
  const editBtn = t('edit');
  const closeBtn = t('closeBtn');
  const saveBtn = t('saveBtn');
  const deleteBtn = t('deleteBtn');
  const displayName = t('fields.displayName');
  const legalName = t('fields.legalName');
  const displayNameRequired = t('required', { field: t('fields.displayName') });
  const legalNameRequired = t('required', { field: t('fields.legalName') });
  const ibanRequired = t('required', { field: 'IBAN' });
  const bicRequired = t('required', { field: 'BIC' });
  const displayNameCharExceed = t('exceedChar', { field: t('fields.displayName') });
  const legalNameCharExceed = t('exceedChar', { field: t('fields.legalName') });
  const duplicatedIban = t('extraPaymentInformationDialog.duplicatedIBAN');
  const deleteSuccess = t('extraPaymentInformationDialog.deletedSuccessMsg');
  const editSuccess = t('extraPaymentInformationDialog.editedSuccessMsg');
  const addSuccess = t('extraPaymentInformationDialog.addedSuccessMsg');
  const updatePaymentError = t('extraPaymentInformationDialog.updatePaymentError');
  const updatePaymentStatusError = t('extraPaymentInformationDialog.updatePaymentStatusError');
  const ibanInvalid = t('extraPaymentInformationDialog.ibanInvalid');
  const bicInvalid = t('extraPaymentInformationDialog.bicInvalid');
  const managePayment = t('extraPaymentInformationDialog.managePayment');
  const editSepaInfo = t('extraPaymentInformationDialog.editSepaInfo');

  const closeHandler = () => {
    setIsEditable(false);
    props.onClose();
  };

  /**
   * Edit the paymentInfo vlaues the edit button is pressed
   */
  const editDialogOpener = () => {
    setDeleteAlertDialogIsOpen(true);
  };

  const editConfirmHandler = () => {
    setIsEditable(true);
  };

  /**
   * Remove the paymentInfo from the formik vlaues when the delete button is pressed
   */
  const deleteDialogOpener = async () => {
    // open the alert dialog
    setIsDeleteable(true);
    setDeleteAlertDialogIsOpen(true);
  };

  const deleteConfirmHandler = () => {
    setIsDeleteable(false);
    setIsDeleting(true);
    // Perform deletion operation
    formik.values.extraPaymentInformation = formik.values.extraPaymentInformation?.filter(
      (paymentInfo) => paymentInfo.iban !== props.paymentInfoForEdit.iban
    );
    props.onClose();

    formik.handleSubmit();
  };

  /**
   * Send updated member state via API when the save button is pressed
   */
  const handleSave = async function (member: MemberDto) {
    // Trim IBAN and BIC
    member.extraPaymentInformation?.forEach((paymentInfo) => {
      if (paymentInfo) {
        paymentInfo.iban = paymentInfo.iban.trim();
      }

      if (paymentInfo) {
        paymentInfo.bic = paymentInfo.bic.trim();
      }
    });

    if (!isDeleting) {
      // Combine the IBANs and BICs in a set
      const allIbans: Set<string> = new Set<string>();

      props.members.extraPaymentInformation?.forEach((paymentInfo) => {
        allIbans.add(paymentInfo.iban);
      });

      if (props.members.paymentInformation) {
        allIbans.add(props.members.paymentInformation.iban);
      }

      // Check the uniqueness of IBAN
      let isIbanUnique = true;

      if (formik.values.extraPaymentInformation) {
        const ibanForEdit = member?.extraPaymentInformation && member.extraPaymentInformation[props.paymentIndex].iban;

        if (props.isEditing) {
          if (props.paymentInfoForEdit.iban === ibanForEdit) {
            isIbanUnique = true;
            allIbans.delete(ibanForEdit);
          }

          if (ibanForEdit) {
            if (allIbans.has(ibanForEdit)) {
              isIbanUnique = false;
              formik.setFieldError(`extraPaymentInformation[${props.paymentIndex}].iban`, duplicatedIban);
            }
          }
        } else {
          const ibanForAdd =
            member?.extraPaymentInformation &&
            member.extraPaymentInformation[formik.values.extraPaymentInformation.length - 1].iban;
          if (ibanForAdd) {
            if (allIbans.has(ibanForAdd)) {
              isIbanUnique = false;

              formik.setFieldError(
                `extraPaymentInformation[${formik.values.extraPaymentInformation.length - 1}].iban`,
                duplicatedIban
              );
            }
          }
        }
      }

      if (!isIbanUnique) return;
    }

    // Map to MemberRequest
    const memberRequest: MemberRequest = {
      paymentInformation: member.paymentInformation,
      extraPaymentInformation: member.extraPaymentInformation !== undefined ? member.extraPaymentInformation : []
    };

    // Call MemberApi to send updated member
    try {
      const updateMember = async () => {
        const memberApi = await getMemberApi();
        return await memberApi.updateMember(memberRequest);
      };

      const result = await trackPromise(updateMember(), 'payment-information-dialog');
      // Show snackbar based on result
      if (result) {
        if (isDeleting) {
          showSuccess(`${props.paymentInfoForEdit.displayName} ${deleteSuccess}`);
        } else if (props.isEditing) {
          showSuccess(`${props.paymentInfoForEdit.displayName} ${editSuccess}`);
        } else {
          showSuccess(addSuccess);
        }

        props.onClose();
      } else {
        showError(updatePaymentError);
      }
    } catch (e) {
      // Show error in snackbar
      if (e instanceof Response) {
        showError(`${updatePaymentStatusError} "${e.status}": ${await e.text()}`);
      } else {
        showError(JSON.stringify(e));
      }
    }

    // Rest states
    if (props.isEditing) {
      setIsEditable(false);
    }
    setIsDeleting(false);
    props.onChange();
  };

  /**
   * Check if IBAN is valid
   * Based on https://rosettacode.org/wiki/IBAN#JavaScript
   */
  const checkIban = function (iban: string | undefined): boolean {
    if (!iban) return false;

    iban = iban.trim();

    if (!iban.match(/^[\dA-Z\s]+$/)) return false;
    const len = iban.length;
    iban = iban.substr(4) + iban.substr(0, 4);
    let s = '';
    for (let i = 0; i < len; i += 1) {
      s += parseInt(iban.charAt(i), 36);
    }
    let m = parseInt(s.substr(0, 15)) % 97;
    for (s = s.substr(15); s; s = s.substr(13)) m = parseInt(m + s.substr(0, 13)) % 97;
    return m == 1;
  };

  /**
   * Check if BIC is valid
   * Regex based on https://wiki.xmldation.com/Support/ISO20022/General_Rules/BIC
   */
  const checkBic = function (bic: string | undefined): boolean {
    if (!bic) return false;

    bic = bic.trim();

    return bic.match(/^[A-Z\s]{6}[A-Z2-9\s][A-NP-Z0-9\s]([A-Z0-9\s]{3}){0,1}$/) != null;
  };

  const validationSchema = yup.object({
    extraPaymentInformation: yup.array(
      yup.object({
        displayName: yup.string().required(displayNameRequired).max(128, displayNameCharExceed),
        legalName: yup.string().required(legalNameRequired).max(128, legalNameCharExceed),
        iban: yup
          .string()
          .required(ibanRequired)
          .test('test-iban', ibanInvalid, (iban) => checkIban(iban)),
        bic: yup
          .string()
          .required(bicRequired)
          .test('test-bic', bicInvalid, (bic) => checkBic(bic))
      })
    )
  });

  const formik = useFormik({
    initialValues: {} as MemberDto,
    validationSchema: validationSchema,
    onSubmit: handleSave
  });

  // Load data via memberAPI call
  useEffect(
    () => {
      if (!props.open) return;
      formik.resetForm();

      formik.setValues(props.members);
    },

    // Adding 'formik' to dependencies creates an infinite loop as formik changes every render
    [props.open]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Dialog open={props.open} onClose={closeHandler} aria-labelledby="form-dialog-title">
        {promiseInProgress && <LinearProgress />}
        <DialogTitle id="form-dialog-title">{managePayment}</DialogTitle>
        {!props.isEditing ? (
          <FormikForm formik={formik}>
            {formik.values.extraPaymentInformation && Array.isArray(formik.values.extraPaymentInformation) && (
              <React.Fragment>
                <DialogContent>
                  <DialogContentText style={{ marginBottom: '20px' }}>{editSepaInfo}</DialogContentText>
                  <FormikTextField
                    formik={formik}
                    id={`extraPaymentInformation[${formik.values.extraPaymentInformation.length - 1}].displayName`}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label={displayName}
                    required
                    disabled={promiseInProgress}
                  />
                  <FormikTextField
                    formik={formik}
                    id={`extraPaymentInformation[${formik.values.extraPaymentInformation.length - 1}].legalName`}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label={legalName}
                    required
                    disabled={promiseInProgress}
                  />
                  <FormikTextField
                    formik={formik}
                    id={`extraPaymentInformation[${formik.values.extraPaymentInformation.length - 1}].iban`}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="IBAN"
                    required
                    disabled={promiseInProgress}
                  />
                  <FormikTextField
                    formik={formik}
                    id={`extraPaymentInformation[${formik.values.extraPaymentInformation.length - 1}].bic`}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="BIC"
                    required
                    disabled={promiseInProgress}
                  />
                </DialogContent>
              </React.Fragment>
            )}
            <DialogActions>
              <Button variant="contained" onClick={props.onClose}>
                {closeBtn}
              </Button>
              <Button variant="contained" color="primary" type="submit" disabled={promiseInProgress || !formik.isValid}>
                {saveBtn}
              </Button>
            </DialogActions>
          </FormikForm>
        ) : (
          <FormikForm formik={formik}>
            {formik.values.extraPaymentInformation && Array.isArray(formik.values.extraPaymentInformation) && (
              <React.Fragment>
                <DialogContent>
                  <DialogContentText style={{ marginBottom: '20px' }}>{editSepaInfo}</DialogContentText>
                  <FormikTextField
                    formik={formik}
                    id={`extraPaymentInformation[${props.paymentIndex}].displayName`}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label={displayName}
                    required
                    disabled={promiseInProgress || !isEditable}
                  />
                  <FormikTextField
                    formik={formik}
                    id={`extraPaymentInformation[${props.paymentIndex}].legalName`}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label={legalName}
                    required
                    disabled={promiseInProgress || !isEditable}
                  />
                  <FormikTextField
                    formik={formik}
                    id={`extraPaymentInformation[${props.paymentIndex}].iban`}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="IBAN"
                    required
                    disabled={promiseInProgress || !isEditable}
                  />
                  <FormikTextField
                    formik={formik}
                    id={`extraPaymentInformation[${props.paymentIndex}].bic`}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="BIC"
                    required
                    disabled={promiseInProgress || !isEditable}
                  />
                </DialogContent>
              </React.Fragment>
            )}
            <DialogActions>
              <Button
                variant="contained"
                style={{
                  color: 'white',
                  backgroundColor: '#d11a2a'
                }}
                onClick={deleteDialogOpener}
              >
                {deleteBtn}
              </Button>
              {isEditable ? (
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={promiseInProgress || !formik.isValid}
                >
                  {saveBtn}
                </Button>
              ) : (
                <Button variant="contained" color="primary" onClick={editDialogOpener}>
                  {editBtn}
                </Button>
              )}
              <Button variant="contained" onClick={closeHandler}>
                {closeBtn}
              </Button>
            </DialogActions>
          </FormikForm>
        )}
      </Dialog>
      {deleteAlertDialogIsOpen && (
        <PaymentInfoDeleteEditDialog
          open={deleteAlertDialogIsOpen}
          onClose={() => setDeleteAlertDialogIsOpen(false)}
          onConfirm={isDeleteable ? deleteConfirmHandler : editConfirmHandler}
          isDeleteable={isDeleteable}
          setIsDeleteable={setIsDeleteable}
          setIsEditable={setIsEditable}
          isEditing={props.isEditing}
        />
      )}
    </div>
  );
};

export default ExtraPaymentInformationDialog;
