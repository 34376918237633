import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  ButtonBase,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';

import { PaymentInformationDto, PaymentSummaryDto, ResponseError, WalletDto } from '../../../generated';
import { NumberFormatUnitsText } from '../../assets/numberformat/NumberFormatUnits';
import { NumberFormatEurText } from '../../assets/numberformat/NumberFormatEur';
import { getMemberApi, getPaymentsApi } from '../../../common/keycloak';
import useNotifications from '../../assets/useNotifications';
import { formatCordaX500Name } from '../../../common/format';
import dzbankLogo from '../../../images/DZBank.png';
import { useConfig } from '../../../common/config';
import { Title } from '../../assets/Title';
import { useStyles } from '../../Styles';

/**
 * This component defines the machine transaction overview.
 */
export function WalletPage(): JSX.Element {
  const classes = useStyles();
  const [walletData, setWalletData] = useState({} as WalletDto);
  const { walletPage } = useConfig();
  // const [paymentInformation, setPaymentInformation] = useState({} as PaymentInformationDto);
  const theme = useTheme();

  const { showError } = useNotifications();
  const { promiseInProgress } = usePromiseTracker();

  const fontcolorInitiated = theme.palette.type === 'dark' ? '#bdbdbd' : '#616161';
  const fontcolorPending = theme.palette.type === 'dark' ? '#ff9800' : '#ef6c00';
  const fontcolorConfirmed = theme.palette.type === 'dark' ? '#66bb6a' : '#1b5e20';

  // Trnaslations
  const { t } = useTranslation();
  const machineNameTxt = t('machineName');
  const machineUserTxt = t('machineUser');
  const machineOwnerTxt = t('machineOwner');
  const summaryTxt = t('walletPage.summary');
  const userUnauthorizedTxt = t('userUnauthorized');
  const errorFetchingDataTxt = t('errorFetchingData');
  const paymentCountTxt = t('walletPage.paymentCount');
  const activityUnitsTxt = t('machineActivityTable.activityUnits');
  const TotalAmountPendingTxt = t('walletPage.TotalAmountPending');
  const TotalAmountInitiatedTxt = t('walletPage.TotalAmountInitiated');
  const TotalAmountConfirmedTxt = t('walletPage.TotalAmountConfirmed');

  // Fetch wallet data
  useEffect(() => {
    const loadWalletData = async () => {
      const api = await getPaymentsApi();
      try {
        const result = await api.getWallet();
        setWalletData(result);
      } catch (error) {
        if (error instanceof ResponseError) {
          if (error.response && error.response.status === 401) {
            showError(userUnauthorizedTxt);
          } else {
            showError(error.message);
          }
        } else {
          showError(errorFetchingDataTxt);
        }
      }
    };
    trackPromise(loadWalletData());
  }, [showError]);

  // // Fetch payment information
  // useEffect(() => {
  //   const loadPaymentInformation = async () => {
  //     const api = await getMemberApi();
  //     try {
  //       const result = await api.getMember();

  //       // TODO: There is also Extra Payment Information which is a list.
  //       //       All objects need to be displayed
  //       setPaymentInformation(result.paymentInformation);
  //     } catch (error) {
  //       if (error instanceof ResponseError) {
  //         if (error.response && error.response.status === 401) {
  //           showError('User Unauthorized!');
  //         } else {
  //           showError(error.message);
  //         }
  //       } else {
  //         showError('There was an error fetching the data!');
  //       }
  //     }
  //   };
  //   trackPromise(loadPaymentInformation());
  // }, [showError]);

  const renderMachineOverview = () => {
    if (Object.entries(walletData).length !== 0) {
      return walletData.machinePaymentSummaries?.map((row: PaymentSummaryDto) => (
        <TableRow key={row.machineName}>
          <TableCell align="left">{row.machineName}</TableCell>
          <TableCell align="left">{formatCordaX500Name(row.machineOwner)}</TableCell>
          <TableCell align="left">{formatCordaX500Name(row.machineUser)}</TableCell>
          <TableCell align="right">
            <NumberFormatUnitsText value={row.totalUnits} />
          </TableCell>
          <TableCell align="right">{row.totalPayments}</TableCell>
          <TableCell align="right" style={{ color: fontcolorInitiated }}>
            <NumberFormatEurText value={row.totalAmountInitiated} />
          </TableCell>
          <TableCell align="right" style={{ color: fontcolorPending }}>
            <NumberFormatEurText value={row.totalAmountPending} />
          </TableCell>
          <TableCell align="right" style={{ color: fontcolorConfirmed }}>
            <NumberFormatEurText value={row.totalAmountConfirmed} />
          </TableCell>
        </TableRow>
      ));
    }
  };

  const renderSummary = () => {
    if (walletData.totalPaymentSummary != null) {
      return (
        <TableRow>
          <TableCell className={classes.summaryFooter}>{summaryTxt}</TableCell>
          <TableCell />
          <TableCell />
          <TableCell className={classes.summaryFooter} align="right">
            <NumberFormatUnitsText value={walletData.totalPaymentSummary.totalUnits} />
          </TableCell>
          <TableCell className={classes.summaryFooter} align="right">
            {walletData.totalPaymentSummary.totalPayments}
          </TableCell>
          <TableCell className={classes.summaryFooter} align="right" style={{ color: fontcolorInitiated }}>
            <NumberFormatEurText value={walletData.totalPaymentSummary.totalAmountInitiated} />
          </TableCell>
          <TableCell className={classes.summaryFooter} align="right" style={{ color: fontcolorPending }}>
            <NumberFormatEurText value={walletData.totalPaymentSummary.totalAmountPending} />
          </TableCell>
          <TableCell className={classes.summaryFooter} align="right" style={{ color: fontcolorConfirmed }}>
            <NumberFormatEurText value={walletData.totalPaymentSummary.totalAmountConfirmed} />
          </TableCell>
        </TableRow>
      );
    }
  };

  // const OverviewBox = () => {
  //   return promiseInProgress ? (
  //     <CircularProgress />
  //   ) : (
  //     <Grid container direction="row" alignItems="center">
  //       <Grid item xs={4}>
  //         <ButtonBase>
  //           <img alt="complex" className={classes.dzBankLogo} src={dzbankLogo} />
  //         </ButtonBase>
  //       </Grid>
  //       <Grid item xs={8} sm container>
  //         <Grid item xs container direction="column" spacing={2}>
  //           <Grid item>
  //             <Typography variant="subtitle1">{paymentInformation?.legalName || 'N/A'}</Typography>
  //             <Typography variant="body2" color="textSecondary">
  //               Legal Name
  //             </Typography>
  //           </Grid>
  //           <Grid item>
  //             <Typography variant="subtitle1">{paymentInformation?.iban || 'N/A'}</Typography>
  //             <Typography variant="body2" color="textSecondary">
  //               IBAN
  //             </Typography>
  //           </Grid>
  //           <Grid item>
  //             <Typography variant="subtitle1">{paymentInformation?.bic || 'N/A'}</Typography>
  //             <Typography variant="body2" color="textSecondary">
  //               BIC
  //             </Typography>
  //           </Grid>
  //           <Grid item>
  //             <Typography gutterBottom variant="subtitle1">
  //               {walletData.accountBalance ? <NumberFormatEurText value={walletData.accountBalance} /> : 'N/A'}
  //             </Typography>
  //             <Typography variant="body2" color="textSecondary">
  //               Total
  //             </Typography>
  //           </Grid>
  //         </Grid>
  //       </Grid>
  //     </Grid>
  //   );
  // };

  return (
    <React.Fragment>
      <Grid container alignItems="flex-start" direction="column" spacing={2}>
        {/* <Grid item xs={12} style={{ flex: 1 }}>
          <Title>My Wallet</Title>
        </Grid>
        {walletPage.showOverviewBox && (
          <Grid item>
            <Paper className={classes.paperBox}>
              <OverviewBox />
            </Paper>
          </Grid>
        )} */}
        <Grid item xs={12}>
          <Paper className={classes.papertable}>
            <Table className={classes.Table}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">{machineNameTxt}</TableCell>
                  <TableCell align="left">{machineOwnerTxt}</TableCell>
                  <TableCell align="left">{machineUserTxt}</TableCell>
                  <TableCell align="right">{activityUnitsTxt}</TableCell>
                  <TableCell align="right">{paymentCountTxt}</TableCell>
                  <TableCell align="right">{TotalAmountInitiatedTxt}</TableCell>
                  <TableCell align="right">{TotalAmountPendingTxt}</TableCell>
                  <TableCell align="right">{TotalAmountConfirmedTxt}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {renderMachineOverview()}
                {renderSummary()}
              </TableBody>
            </Table>
            {promiseInProgress && (
              <div className={classes.spinner}>
                <CircularProgress />
              </div>
            )}
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
