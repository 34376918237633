import { KeycloakTokenParsed } from 'keycloak-js';
import { useTranslation } from 'react-i18next';
import React from 'react';
import Divider from '@material-ui/core/Divider';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';

import { useConfig } from '../../common/config';
import keycloak from '../../common/keycloak';

type ParsedToken = KeycloakTokenParsed & {
  email?: string;
  preferred_username?: string;
  given_name?: string;
  family_name?: string;
  name?: string;
};

export default function UserMenu(props: { anchorEl: HTMLElement | null; onClose: () => void }): JSX.Element {
  const parsedToken: ParsedToken | undefined = keycloak?.tokenParsed;

  const { dashboard } = useConfig();
  // Trnaslations
  const { t } = useTranslation();
  const profile = t('userMenu.profile');
  const user = t('userMenu.user');

  console.log('Parsed Keycloak Token:', parsedToken);

  return (
    <Menu
      id="simple-menu"
      anchorEl={props.anchorEl}
      keepMounted
      open={Boolean(props.anchorEl)}
      onClose={props.onClose}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <MenuItem disabled={true}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText>{`${profile} ${dashboard.profile}`}</ListItemText>
      </MenuItem>
      <MenuItem disabled={true}>
        <ListItemIcon>
          <AccountBoxIcon />
        </ListItemIcon>
        <ListItemText>{`${user} ${parsedToken?.preferred_username}`}</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => keycloak.logout()}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </MenuItem>
    </Menu>
  );
}
