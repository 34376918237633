import { CordaX500NameDto, PaymentInformationDto } from '../generated';

/**
 * Function converts into date with format DD/MM/YYYY, HH:MM
 * @function
 * @param date
 */
export const formatDateTime = function (date?: Date): string {
  if (!date) return '';

  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  };
  return new Intl.DateTimeFormat('en-GB', options).format(date);
};

/**
 * Function converts into date with format DD/MM/YYYY
 * @function
 * @param date
 */
export const formatDate = function (date?: Date): string {
  if (!date) return '';

  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  };
  return new Intl.DateTimeFormat('en-GB', options).format(date);
};

/**
 * Function formats the X.500N name into a human readable string
 * @function
 * @param cordaX500Name Corda X.500 name
 */
export const formatCordaX500Name = function (cordaX500Name: CordaX500NameDto): string {
  return `${cordaX500Name.organization} (${cordaX500Name.locality}, ${cordaX500Name.country})`;
};

export const stringToCordaX500Name = function (cordaX500NameString: string): CordaX500NameDto {
  const result = /(.*) \((.*), (.*)\)/.exec(cordaX500NameString);

  if (result == null) throw Error('Not a valid string of a X500Name');

  return {
    organization: result[1],
    locality: result[2],
    country: result[3]
  };
};

/**
 * Function formats the paymentInformation name into a human readable string
 * @function
 * @param PaymentInformation
 */
export const formatPaymentInformation = function (paymentInformation: PaymentInformationDto | undefined): string {
  if (!paymentInformation) {
    return 'paymentInformation is undefined!';
  }
  return `${paymentInformation.displayName}, ${paymentInformation.legalName}, ${paymentInformation.iban}, ${paymentInformation.bic}`;
};

export const stringToPaymentInformation = function (
  paymentInformationString: string | undefined
): PaymentInformationDto | undefined {
  if (paymentInformationString === undefined || paymentInformationString === '') return undefined;

  const result = /^([^,]+),\s*([^,]+),\s*([^,]+),\s*([^,]+)$/.exec(paymentInformationString);

  if (result == null) throw Error('Not a valid string of a paymentInformation');

  return {
    displayName: result[1],
    legalName: result[2],
    iban: result[3],
    bic: result[4]
  };
};

/**
 * Function to return true if the date of the searchDateString matches the date of Date
 * @param searchDateString
 * @param date
 */
export function dateComparer(searchDateString: string, date?: Date): boolean {
  if (!date) return false;

  const searchDate = new Date(searchDateString);
  if (searchDate == null) return true;

  return (
    date.getFullYear() === searchDate.getFullYear() &&
    date.getMonth() === searchDate.getMonth() &&
    date.getDate() === searchDate.getDate()
  );
}

/**
 * Function formats the paymentConfigurationDto into a human readable string
 * @function
 * @param paymentConfiguration
 */
export const formatPaymentConfigurationDto = function (dayOfMonth: number): string {
  // const daysOfMonthStr = daysOfMonth && daysOfMonth.length > 0 ? daysOfMonth.join(', ') : 'None';

  return `${dayOfMonth}`;
};

export const stringToPaymentConfigurationDto = function (dayOfMonth: string): number {
  // const daysOfMonthStr = daysOfMonth && daysOfMonth.length > 0 ? daysOfMonth.join(', ') : 'None';

  return Number(dayOfMonth);
};
